///
/// Future Imperfect by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Badge */

.badge {
	@include vendor('display', 'flex');
	@include vendor('flex-direction', 'row');
	@include vendor('align-items', 'center');
	// @include vendor('justify-content', 'flex-end');

  background: _palette(bg);
  border: solid 1px _palette(border);
  margin: 0 0 1em;
	padding: 0.8em 0.8em;



  .image {
    overflow: hidden;
    width: 15%;

    img {
      @include vendor( 'transition', 'transform #{_duration(transition)} ease-out');
      width: 100%;
    }

    &:hover {
      img {
        @include vendor( 'transform', 'scale(1.02)');
      }
    }
  }

	
  header {
    z-index: 2;
    @include padding(1.25em, 1.25em, (0, 3em, 0, 0));
    min-height: 4em;
    position: relative;
    @include vendor( 'flex-grow', '1');
		font-family: "Raleway", Helvetica, sans-serif;

    h3 {
			letter-spacing: 0.1em;
      font-size: 1em;
			text-transform: none;
    }

    .published {
      display: block;
      font-family: _font(family-heading);
      font-size: 0.6em;
      font-weight: _font(weight-heading);
      letter-spacing: _font(kerning-heading);
      margin: -0.625em 0 (_size(element-margin) * 0.85) 0;
      text-transform: uppercase;
    }
  }
}

